import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/web-toit.io/web-toit.io/src/components/MdxLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const Section = makeShortcode("Section");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageTitle title="Cookies policy" subTitle="Last updated: February 26, 2025." mdxType="PageTitle" />
    <Section css={{
      textAlign: "left"
    }} mdxType="Section">
      <p>{`The current version of this website does not use cookies.`}</p>
      <h2>{`What are cookies?`}</h2>
      <p>{`A cookie is a small text file that a website saves on your computer or mobile device when you visit the site. It enables the website to remember your actions and preferences (such as login, language, font size and other display preferences) over a period of time, so you don’t have to keep re-entering them whenever you come back to the site or browse from one page to another.`}</p>
      <h2>{`How to control cookies`}</h2>
      <p>{`You can control and/or delete cookies as you wish – for details, see `}<a parentName="p" {...{
          "href": "https://cookiesandyou.com/"
        }}>{`Cookies & You`}</a>{`. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.`}</p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      